<template>
  <div class="create">


    <div class="step" v-if="step === 1">
      <form action="" @submit.prevent="register">
        <h3 class="anim">Créons votre compte</h3>
        <div class="info anim">Inscrivez-vous à l'aide de votre adresse e-mail</div>

        <label class="anim" for="email">Adresse E-mail</label>
        <el-input class="anim" id="email" type="email" autocomplete="on" v-model="email" name="email"></el-input>
        <!--      <div class="info_input anim">Un email de confirmation vous sera envoyé à cette adresse</div>-->

        <label class="anim">Numéro de télephone</label>
        <vue-tel-input class="anim phone" autocomplete="on" v-model="national_phone_number" placeholder="" @validate="phone_validate_method"></vue-tel-input>

        <label class="anim" for="prenom">Prenom</label>
        <el-input class="anim" autocomplete="on" v-model="prenom" name="prenom" id="prenom"></el-input>

        <label class="anim" for="nom">Nom de famille</label>
        <el-input class="anim" autocomplete="on" v-model="nom" name="nom" id="nom"></el-input>

        <label class="anim" for="password">Mot de passe</label>
        <el-input class="anim" id="password" type="password" autocomplete="on" v-model="password" show-password></el-input>

        <div class="info_input anim">Doit contenir au minium 10 caractères</div>


        <el-checkbox class="anim" v-model="optin">Recevez nos offres et informations.</el-checkbox>

        <button class="anim">Créer mon compte</button>
        <div class="info anim">En cliquant sur "Créer mon compte", vous acceptez nos <router-link :to="{ name: 'legal_cgu' }" href="/legal/cgu">termes et conditions & politiques de confidentialité</router-link></div>
      </form>
    </div>



  </div>
</template>

<script>
import anime from 'animejs'
import { VueTelInput } from 'vue-tel-input'
export default {
  data() {
    return {
      step: 1,

      email: null,
      prenom: null,
      nom: null,
      password: null,

      phone_validate: null,
      international_phone_number: null,
      national_phone_number: null,

      optin: false
    }
  },
  props: [ 'router' ],
  name: "join",
  mounted() {
    // this.step_anim()
  },
  components: {
    VueTelInput
  },
  watch: {
    step() {
      // this.step_anim()
    },
    getUser() {
      if(this.router) {
        if (localStorage.getItem("beforeLogin")) {
          let key = localStorage.getItem("beforeLogin")
          if (key === 'true') this.$router.go(-1)
          else this.$router.push({name: localStorage.getItem("beforeLogin")});
          localStorage.removeItem("beforeLogin");
        } else this.$router.push({name: "Home"});
      }
    }
  },
  computed: {
    getUser() {
      return this.$store.state.user.user
    }
  },
  methods: {
    phone_validate_method(data) {
      this.phone_validate = data
    },
    register() {
      if (!this.email || !this.password || !this.prenom || !this.nom) return


      if (!this.phone_validate || this.phone_validate.isValid !== true) {
        this.$notify({title: "Erreur avec le numéro de téléphone", offset: this.$notifyOffest, type: 'error'});
        return
      }

      this.international_phone_number = this.phone_validate.number.e164
      this.national_phone_number = this.phone_validate.number.national


      this.$http.post('/user/register', {
        nom: this.nom,
        prenom: this.prenom,
        email: this.email,
        password: this.password,
        international_phone_number: this.international_phone_number,
        national_phone_number: this.national_phone_number,
        optin: this.optin
      }).then((r) => {
        console.log(r)
        if (r.status === 200) {
          this.$notify({
            title: 'Bienvenue', offset: this.$notifyOffest, type: 'success',
          });

          this.$http.defaults.headers.common['Authorization'] = `Bearer ${ r.data.token }`

          this.$store.dispatch("user/login", r.data);
          this.$emit('success')
          

          // this.$http.defaults.headers.common['Authorization'] = `Bearer ${ r.data.token }`
          
          // if(this.router) {
          //   if (localStorage.getItem("beforeLogin")) {
          //     let key = localStorage.getItem("beforeLogin")
          //     if (key === 'true') this.$router.go(-1) 
          //     else this.$router.push({name: localStorage.getItem("beforeLogin")});
          //     localStorage.removeItem("beforeLogin");
          //   } else this.$router.push({name: "Home"});
          // }
          // this.$emit('success')
          //
          // this.$store.dispatch("user/login", r.data);
        }
      }).catch((e) => {
        if(e.response)
          this.$notify({ title: e.response.data, offset: this.$notifyOffest, type: 'error' });
      })
    },
    step_anim() {
      anime({ targets: '.anim', opacity: 0, duration: 0 })
      setTimeout(function() {
        anime({
          targets: '.step .anim',
          translateX: ['-30px', 0],
          opacity: [0, 1],
          easing: 'easeOutCubic',
          duration: 400,
          delay: anime.stagger(20)
        })
      }, 0)
    }
  }
}
</script>

<style scoped>

.phone { font-size: 16px; position: relative; z-index: 5 }
.phone .vti__dropdown-list { z-index: 20 }

label.el-checkbox { padding: 0; margin-bottom: 20px; margin-top: 10px; text-transform: none; }

.create .step { background: #fff; padding: 32px; box-shadow: 0 2px 4px var(--gris); border: 1px solid var(--gris); }


/*.digits { margin-top: 30px; }*/
.anim { opacity: 1 }
.step .info { margin-bottom: 30px; font-size: 12px; margin-top: 14px; letter-spacing: 1.1px; line-height: 24px }
.step .info:last-child { margin-bottom: 0; }


form { display: flex; flex-direction: column }


.button.fb {
  background: #1877f2;
  display: flex;
  justify-content: center; align-items: center;
  text-transform: none;
  font-size: 18px;
  font-weight: 500;
}

.button.fb img {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}

.step { display: flex; flex-direction: column }

.step.fb { display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: calc((var(--vh, 1vh) * 100) - 60px); }
.step.fb img { width: 100%; height: 100%; background: #ccc; border-radius: 100%; border: none; position: relative; z-index: 2; }

.step.fb .img { position: relative;  width: 50vw; height: 50vw;  margin-bottom: 16px;}

.load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 80vw;
}
.load i {
  background: #af36e5;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 100%;
  animation: circle 1.2s linear infinite 0s;
}
.load i:nth-child(2) {
  animation-delay: 0.5s;
}
.load i:nth-child(3) {
  animation-delay: 1s;
}

@keyframes circle {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  10% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}



</style>